<template>
  <div>
    <div v-if="aproved">
      <parametros />
      <hr>
      <tabelaOperacoes />
      <hr>
      <totais />
      <hr>
      <actionsFooter
        :update-tabs="updateTabs"
        :toggle-negociar="toggleNegociar"
      />
    </div>

    <b-modal
      ref="selectCredor"
      cancel-variant="secondary"
      ok-only
      ok-title="Selecionar"
      centered
      size="md"
      no-close-on-backdrop
      :title="`cliente com ${credores.length} credores`"
      :ok-disabled="!selectedCredor"
      @ok="setCredor(selectedCredor)"
    >
      <label>Selecione o credor</label>
      <v-select
        label="nome"
        :value="selectedCredor"
        :options="credores"
        :clearable="false"
        @input="getItem"
      />
    </b-modal>
  </div>
</template>

<script>
import {
  BModal,
  VBPopover,
} from 'bootstrap-vue'
import vSelect from 'vue-select'

import dayjs from 'dayjs'
import dayjsBusinessDays from 'dayjs-business-days'
import { mapState } from 'vuex'

// IMportando COmponents
import actionsFooter from './components/actionsFooter.vue'
import Parametros from './components/parametros.vue'
import tabelaOperacoes from './components/tabelaOperacoes.vue'
import totais from './components/totais.vue'

dayjs.extend(dayjsBusinessDays)

export default {
  components: {
    vSelect,
    BModal,

    // COmponents
    Parametros,
    tabelaOperacoes,
    totais,
    actionsFooter,
  },
  directives: {
    'b-popover': VBPopover,
  },
  props: {
    toggleNegociar: {
      type: Function,
      required: true,
    },
    updateTabs: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      selectedCredor: null,
    }
  },
  computed: {
    ...mapState({
      credorSelected: state => {
        const { credorSelected } = state.negociar
        return credorSelected
      },
      credores: state => {
        const { credores } = state.negociacao
        return credores
      },
      operacoesCobranca: state => {
        const { operacoesCredor } = state.negociar
        return operacoesCredor
      },
      operacoesSelected: state => state.negociar.operacoesSelected,
      clienteInativo: state => state.negociar.clienteInativo,
    }),
    credores: {
      get() {
        return this.$store.state.negociacao.credores
      },
      set(value) {
        this.$store.commit('negociar/setCredor', value)
      },
    },
    isSituacao() {
      const { id } = this.credorSelected
      if (id) {
        // const { id } = credor
        if (id === parseInt(process.env.VUE_APP_ROOT_FAFIRE) || id === parseInt(process.env.VUE_APP_ROOT_EDUCA_MAIS) || id === parseInt(process.env.VUE_APP_ROOT_i9)) {
          return true
        }
      }
      return false
    },
    aproved() {
      if (this.credorSelected) {
        if (this.operacoesCobranca.length > 0) {
          return true
        }
      }
      return false
    },
  },

  watch: {
    operacoesSelected() {
      this.setParametro()
    },
  },

  mounted() {
    const DOM_BOSCO_LIST = [129, 130, 133]
    if (this.credores.length > 1) {
      const isDomBosco = this.credores.filter(credor => DOM_BOSCO_LIST.includes(credor.id)).length > 0
      if (isDomBosco && isDomBosco.length === this.credores.length) {
        this.setCredor(this.credores[0])
      } else {
        this.$refs.selectCredor.show()
      }
    } else {
      this.setCredor(this.credores[0])
    }

    const now = new Date()
    const dataEntrada = dayjs(now).businessDaysAdd(1).format('YYYY-MM-DD')

    this.$store.commit('negociar/setDataEntrada', dataEntrada)
  },

  methods: {
    getItem(item) {
      this.selectedCredor = item
    },
    setCredor(item) {
      this.$store.commit('negociar/setCredor', item)
      this.$store.dispatch('negociar/setOperacoesCobranca')
      this.setParametro()
    },

    setParametro() {
      const DOM_BOSCO_LIST = [129, 130, 133]

      let { parametros } = this.credorSelected

      const { id } = this.credorSelected

      if (DOM_BOSCO_LIST.includes(id)) {
        if (id === 129) {
          const defaultCredorParametro = this.credores.filter(e => e.id === 129)[0]
          parametros = defaultCredorParametro.parametros
        }
      }

      this.$store.commit('negociar/setParametroDefault', parametros.default)
      let key = null
      const setParametro = items => {
        const periodosOperacoes = []
        items.map(item => {
          const dataBr = item.data_vencimento ? item.data_vencimento.split('/') : null
          if (dataBr) {
            const periodo = `${dataBr[2]}.${parseInt(dataBr[1]) <= 6 ? 1 : 2}`
            periodosOperacoes.push(periodo)
          }
        })
        const sorted = periodosOperacoes.sort((a, b) => ((a < b) ? 1 : -1))
        if (!this.isSituacao) {
          sorted.map(item => {
            if (!key) {
              parametros[item] ? key = item : null
            }
          })
        }
        if (!key) {
          const keys = Object.keys(parametros).filter(e => e !== 'default')
          const sortedKeys = keys.sort((a, b) => ((sorted[0] < b) ? 1 : -1))

          if (sortedKeys[0]) {
            if (this.isSituacao) {
              const year = sortedKeys[0].split('.')[0]
              if (!this.clienteInativo) {
                key = `${year}.1`
              } else {
                key = `${year}.2`
              }
            } else {
              // eslint-disable-next-line prefer-destructuring
              key = sortedKeys[0]
            }
          } else if (this.isSituacao) {
            const year = keys[0].split('.')
            if (!this.clienteInativo) {
              key = `${year}.1`
            } else {
              key = `${year}.2`
            }
          } else {
            // eslint-disable-next-line prefer-destructuring
            key = keys[0]
          }
        }
      }

      if (this.operacoesSelected.length > 0) {
        setParametro(this.operacoesSelected)
      } else {
        setParametro(this.operacoesCobranca)
      }

      this.$store.commit('negociar/setParametro', {
        parametroCompleto: parametros[key],
        parametroOptions: Object.keys(parametros),
        parametroKey: key,
      })
    },
  },
}
</script>

<style>
  .table-adjust {
    max-width: 600px;
    align-items: center;
    justify-content: center;
  }
</style>
