<template>
  <div v-if="avaible">
    <b-card>
      <span>Parâmetros Gerais </span>
      <b-col>
        <b-row>
          <b-col v-if="credores.length > 1">
            <label>Selecione o Credor</label>
            <v-select
              v-model="credoresSelectedInfo"
              label="nome"
              :options="credores"
              :clearable="false"
              @input="getItem"
            />
          </b-col>
          <b-col lg="2">
            <label>Negativação</label>
            <v-select
              v-model="infoCredor.negativacao"
              disabled
            />
          </b-col>
          <b-col lg="2">
            <label>Prazo de Remessa</label>
            <v-select
              v-model="infoCredor.prazoRemessa"
              disabled
            />
          </b-col>
          <b-col lg="2">
            <label>Número da Operação</label>
            <v-select
              v-model="infoCredor.gerarNumeroOperacao"
              disabled
            />
          </b-col>
          <b-col lg="3">
            <label>Cartão</label>
            <v-select
              v-for="(item,index) in infoCredor.cartao"
              :key="index"
              v-model="item.label"
              disabled
            />
          </b-col>
          <b-col lg="3">
            <label>Razão Social</label>
            <v-select
              v-model="infoCredor.razaoSocial"
              disabled
            />
          </b-col>
        </b-row>
        <b-row>
          <b-col md="1">
            <label for=""><strong>Portal</strong></label>
            <br>
            <b-form-checkbox
              v-model="infoCredor.ativo_portal"
              name="check-button"
              disabled
              switch
              inline
            />
          </b-col>

          <b-col>
            <label for=""><strong>Convênio</strong></label>
            <v-select
              v-model="infoCredor.convenio"
              disabled
              :get-option-label="convenio => `${convenio.convenio} - ${convenio.nome_banco}`"
            />
          </b-col>

          <b-col>
            <label for=""><strong>Correção Monetário</strong></label>
            <v-select
              v-model="infoCredor.correcao_monetario"
              disabled
            />
          </b-col>
          <b-col md="2">
            <label for=""><strong>Base Parcela</strong></label>
            <money
              v-model="infoCredor.base_parcela"
              v-bind="money"
              class="form-control"
              disabled
            />
          </b-col>
        </b-row>
        <b-row class="mt-1">
          <b-col md="3">
            <label for="">Hon. Fixo ano em curso</label>
            <b-form-input
              v-model="infoCredor.comissao_fixa"
              type="number"
              disabled
            />
          </b-col>
          <b-col md="3">
            <label for="">Hon. Fixo Baixa Credor</label>
            <b-form-input
              v-model="infoCredor.comissao_baixa_credor"
              type="number"
              disabled
            />
          </b-col>
        </b-row>
      </b-col>
    </b-card>
    <b-card>
      <b-row>
        <b-col
          v-if="obs != null"
          md="12"
        >
          <b-alert
            variant="warning"
            show
          >
            <h5>
              <div v-html="obs" />
            </h5>
          </b-alert>
        </b-col>
      </b-row>
    </b-card>
    <b-card>
      <b-row
        class="mb-1"
      >
        <b-col
          v-if="credores.length > 1"
          md="4"
        >
          <label>Selecione o Credor</label>
          <v-select
            v-model="credorSelected"
            :options="credorOptions"
            :clearable="false"
          />
        </b-col>
        <b-col md="3">
          <label>Selecione o parametro</label>
          <v-select
            v-model="keys.selected"
            :options="setKeys"
            :clearable="false"
          />
        </b-col>
        <b-col md="3">
          <label>Forma de pagamento</label>
          <v-select
            v-model="formaPagemento"
            :options="setFormaPagamento"
            :clearable="false"
          />
        </b-col>
        <b-col md="2">
          <label>Método de pagamento</label>
          <v-select
            v-model="metodoPagamento"
            :options="setMetodoPagamento"
            :clearable="false"
          />
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-table-simple
            hover
            small
            caption-top
            responsive
            bordered
            :items="setItens"
          >
            <colgroup><col><col></colgroup>
            <colgroup><col><col><col></colgroup>
            <colgroup><col><col></colgroup>
            <b-thead head-variant="warning">
              <b-tr>
                <b-th
                  v-if="formaPagemento === 'parcelado'"
                  colspan="1"
                  class="text-center"
                >
                  Entrada
                </b-th>
                <b-th
                  v-if="formaPagemento === 'parcelado'"
                  colspan="1"
                  class="text-center"
                >
                  QNT. PARCELAS
                </b-th>
                <b-th
                  colspan="2"
                  rowspan="2"
                  class="text-center"
                >
                  JUROS
                </b-th>
                <b-th
                  colspan="2"
                  rowspan="2"
                  class="text-center"
                >
                  MULTA
                </b-th>
                <b-th
                  :colspan=" remuneracaoParametro === 'Comissão' ? '1' : '2'"
                  rowspan="2"
                  class="text-center"
                >
                  {{ remuneracaoParametro === 'Honorário' ? 'ENCARGOS COBRADOS' : 'COMISSÃO FIXA' }}
                </b-th>
                <b-th
                  colspan="2"
                  rowspan="2"
                  class="text-center"
                >
                  DESCONTO
                </b-th>
                <b-th
                  colspan="1"
                  class="text-center"
                >
                  PONTUALIDADE
                </b-th>
              </b-tr>
            </b-thead>
            <b-tbody>
              <b-tr>
                <b-th v-show="formaPagemento === 'parcelado'" />
                <b-th v-show="formaPagemento === 'parcelado'" />
                <b-th class="text-center">
                  Mínimo
                </b-th>
                <b-th class="text-center">
                  Máximo
                </b-th>
                <b-th class="text-center">
                  Mínimo
                </b-th>
                <b-th class="text-center">
                  Máximo
                </b-th>
                <b-th
                  v-show="remuneracaoParametro !=='Comissão'"
                  class="text-center"
                >
                  Mínimo
                </b-th>
                <b-th class="text-center">
                  {{ remuneracaoParametro === 'Comissão' ? '' : 'Máximo' }}
                </b-th>
                <b-th class="text-center">
                  Mínimo
                </b-th>
                <b-th class="text-center">
                  Máximo
                </b-th>
              </b-tr>
              <b-th
                v-if="formaPagemento === 'parcelado'"
                class="text-center"
              >
                {{ setItens[0].entrada ? setItens[0].entrada : '' }}
              </b-th>
              <b-th
                v-if="formaPagemento === 'parcelado'"
                class="text-center"
              >
                {{ setItens[0].nParcelas }}
              </b-th>
              <b-th class="text-center">
                {{ setItens[0].juros }}%
              </b-th>
              <b-th class="text-center">
                {{ setItens[0].juros_max }}%
              </b-th>
              <b-th class="text-center">
                {{ setItens[0].multa }}%
              </b-th>
              <b-th class="text-center">
                {{ setItens[0].multa_max }}%
              </b-th>
              <b-th
                v-show="remuneracaoParametro ==='Honorário'"
                class="text-center"
              >
                {{ setItens[0].remuneracao }}%
              </b-th>
              <b-th class="text-center">
                {{ setItens[0].remuneracao_max }}%
              </b-th>
              <b-th class="text-center">
                {{ setItens[0].desconto }}%
              </b-th>
              <b-th class="text-center">
                {{ setItens[0].desconto_max }}%
              </b-th>
              <b-th class="text-center">
                {{ setItens[0].desconto_concedido ? setItens[0].desconto_concedido.label : '' }}
              </b-th>
            </b-tbody>
          </b-table-simple></b-col>
      </b-row>
      <b-col
        cols="2"
        class="mt-2 mx-auto"
      >
        <b-button
          v-show="!moreParametro"
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          @click="repeateParametro"
        >
          <feather-icon
            icon="PlusIcon"
            class="mr-25"
          />
        </b-button>
      </b-col>
    </b-card>
    <!-- Card 2-->
    <b-card v-if="moreParametro">
      <b-row
        class="mb-1"
      >
        <b-col
          v-if="credores.length > 1"
          md="4"
        >
          <label>Selecione o Credor</label>
          <v-select
            v-model="credorSelected2"
            :options="credorOptions2"
            :clearable="false"
          />
        </b-col>
        <b-col md="3">
          <label>Selecione o parametro</label>
          <v-select
            v-model="keys2.selected"
            :options="setKeys2"
            :clearable="false"
          />
        </b-col>
        <b-col md="3">
          <label>Forma de pagamento</label>
          <v-select
            v-model="formaPagemento2"
            :options="setFormaPagamento2"
            :clearable="false"
          />
        </b-col>
        <b-col md="2">
          <label>Método de pagamento</label>
          <v-select
            v-model="metodoPagamento2"
            :options="setMetodoPagamento2"
            :clearable="false"
          />
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-table-simple
            hover
            small
            caption-top
            responsive
            bordered
            :items="setItens2"
          >
            <colgroup><col><col></colgroup>
            <colgroup><col><col><col></colgroup>
            <colgroup><col><col></colgroup>
            <b-thead head-variant="warning">
              <b-tr>
                <b-th
                  v-if="formaPagemento2 === 'parcelado'"
                  colspan="1"
                  class="text-center"
                >
                  Entrada
                </b-th>
                <b-th
                  v-if="formaPagemento2 === 'parcelado'"
                  colspan="1"
                  class="text-center"
                >
                  QNT. PARCELAS
                </b-th>
                <b-th
                  colspan="2"
                  rowspan="2"
                  class="text-center"
                >
                  JUROS
                </b-th>
                <b-th
                  colspan="2"
                  rowspan="2"
                  class="text-center"
                >
                  MULTA
                </b-th>
                <b-th
                  :colspan=" remuneracaoParametro2 === 'Comissão' ? '1' : '2'"
                  rowspan="2"
                  class="text-center"
                >
                  {{ remuneracaoParametro2 === 'Honorário' ? 'ENCARGOS COBRADOS' : 'COMISSÃO FIXA' }}
                </b-th>
                <b-th
                  colspan="2"
                  rowspan="2"
                  class="text-center"
                >
                  DESCONTO
                </b-th>
                <b-th
                  colspan="1"
                  class="text-center"
                >
                  PONTUALIDADE
                </b-th>
              </b-tr>
            </b-thead>
            <b-tbody>
              <b-tr>
                <b-th v-show="formaPagemento2 === 'parcelado'" />
                <b-th v-show="formaPagemento2 === 'parcelado'" />
                <b-th class="text-center">
                  Mínimo
                </b-th>
                <b-th class="text-center">
                  Máximo
                </b-th>
                <b-th class="text-center">
                  Mínimo
                </b-th>
                <b-th class="text-center">
                  Máximo
                </b-th>
                <b-th
                  v-show="remuneracaoParametro2 !=='Comissão'"
                  class="text-center"
                >
                  Mínimo
                </b-th>
                <b-th class="text-center">
                  {{ remuneracaoParametro2 === 'Comissão' ? '' : 'Máximo' }}
                </b-th>
                <b-th class="text-center">
                  Mínimo
                </b-th>
                <b-th class="text-center">
                  Máximo
                </b-th>
              </b-tr>
              <b-th
                v-if="formaPagemento2 === 'parcelado'"
                class="text-center"
              >
                {{ setItens2[0].entrada ? setItens2[0].entrada : '' }}
              </b-th>
              <b-th
                v-if="formaPagemento2 === 'parcelado'"
                class="text-center"
              >
                {{ setItens2[0].nParcelas }}
              </b-th>
              <b-th class="text-center">
                {{ setItens2[0].juros }}%
              </b-th>
              <b-th class="text-center">
                {{ setItens2[0].juros_max }}%
              </b-th>
              <b-th class="text-center">
                {{ setItens2[0].multa }}%
              </b-th>
              <b-th class="text-center">
                {{ setItens2[0].multa_max }}%
              </b-th>
              <b-th
                v-show="remuneracaoParametro2 ==='Honorário'"
                class="text-center"
              >
                {{ setItens2[0].remuneracao }}%
              </b-th>
              <b-th class="text-center">
                {{ setItens2[0].remuneracao_max }}%
              </b-th>
              <b-th class="text-center">
                {{ setItens2[0].desconto }}%
              </b-th>
              <b-th class="text-center">
                {{ setItens2[0].desconto_max }}%
              </b-th>
              <b-th class="text-center">
                {{ setItens2[0].desconto_concedido ? setItens2[0].desconto_concedido.label : '' }}
              </b-th>
            </b-tbody>
          </b-table-simple></b-col>
      </b-row>
      <b-col
        cols="2"
        class="mt-2 mx-auto"
      >
        <b-button
          v-show="moreParametro"
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          @click="ocultParametro"
        >
          <feather-icon
            icon="MinusIcon"
            class="mr-25"
          />
        </b-button>
      </b-col>
    </b-card>
  </div>
</template>

<script>
import vSelect from 'vue-select'
import { mapState } from 'vuex'
import {
  BRow,
  BCol,
  BTableSimple,
  BCard,
  BAlert,
  BThead,
  BTr,
  BTh,
  BTbody,
  BButton,
  BFormCheckbox,
  BFormInput,
} from 'bootstrap-vue'
import { Money } from 'v-money'
import Ripple from 'vue-ripple-directive'
import axios from '../../../../../axios-auth'

export default {
  components: {
    vSelect,
    BRow,
    BCol,
    BTableSimple,
    BCard,
    BAlert,
    BThead,
    BTr,
    BTh,
    BTbody,
    BButton,
    BFormCheckbox,
    BFormInput,
    Money,
  },
  directives: {
    Ripple,
  },
  props: {
    // credores: {
    //   type: Array,
    //   required: true,
    // },
  },
  data() {
    return {
      money: {
        decimal: ',',
        thousands: '.',
        prefix: 'R$ ',
        suffix: '',
        precision: 2,
        masked: false,
      },
      keys: {
        selected: '',
      },
      keys2: {
        selected: '',
      },
      formaPagemento: '',
      metodoPagamento: '',
      credorSelected: null,
      formaPagemento2: '',
      metodoPagamento2: '',
      credorSelected2: null,
      credorPos: 0,
      parametro: {},
      parametro2: {},
      avaible: false,
      obs: null,
      fields: [
        { key: 'juros', label: 'Juros' },
        { key: 'multa', label: 'Multa' },
        { key: 'remuneracao', label: 'Encargos cobrados' },
        { key: 'desconto', label: 'Desconto' },
        { key: 'desconto_concedido', label: 'Pontualidade' },
      ],
      moreParametro: false,
      remuneracaoParametro: '',
      remuneracaoParametro2: '',
      infoCredor: {
        negativacao: null,
        prazoRemessa: null,
        gerarNumeroOperacao: null,
        razaoSocial: null,
        cartao: null,
        convenio: null,
        correcao_monetario: null,
        opcao_cartao: null,
        ativo_portal: true,
        observacoes: null,
        comissao_fixa: 12,
        comissao_baixa_credor: 20,
        base_parcela: 0,
      },
      credoresSelectedInfo: null,
      selectedCredor: null,
    }
  },
  computed: {
    ...mapState({
      credores: state => {
        const { credores } = state.negociacao
        return credores
      },
      parametroKey: state => state.negociar.parametroKey,
    }),
    setKeys() {
      const { credores, credorPos } = this
      const { parametros } = credores[credorPos]
      if (parametros) {
        const keys = Object.keys(parametros)
        // eslint-disable-next-line prefer-destructuring
        this.keys.selected = this.parametroKey ? this.parametroKey : keys.reverse()[1]
        return keys
      }
      return {}
    },
    setKeys2() {
      const { credores, credorPos } = this
      const { parametros } = credores[credorPos]
      if (parametros) {
        const keys = Object.keys(parametros)
        // eslint-disable-next-line prefer-destructuring
        this.keys2.selected = this.parametroKey ? this.parametroKey : keys.reverse()[1]
        return keys
      }
      return {}
    },
    setFormaPagamento() {
      let formaPagemento = []
      const { credores, credorPos } = this
      const { parametros } = credores[credorPos]
      const { selected } = this.keys
      const remuneracaoPara = Object.entries(parametros[selected])
      remuneracaoPara[0].map(item => {
        this.remuneracaoParametro = item.label
      })
      formaPagemento = Object.keys(parametros[selected]).filter(e => e === 'avista' || e === 'parcelado')
      // eslint-disable-next-line prefer-destructuring
      this.formaPagemento = formaPagemento[0]

      return formaPagemento
    },
    setFormaPagamento2() {
      let formaPagemento2 = []
      const { credores, credorPos } = this
      const { parametros } = credores[credorPos]
      const { selected } = this.keys2
      const remuneracaoPara2 = Object.entries(parametros[selected])
      remuneracaoPara2[0].map(item => {
        this.remuneracaoParametro2 = item.label
      })
      formaPagemento2 = Object.keys(parametros[selected]).filter(e => e === 'avista' || e === 'parcelado')
      // eslint-disable-next-line prefer-destructuring
      this.formaPagemento2 = formaPagemento2[0]

      return formaPagemento2
    },
    setMetodoPagamento() {
      let options = []
      const { formaPagemento, credores, credorPos } = this
      const { parametros } = credores[credorPos]
      const { selected } = this.keys
      const metodos = parametros[selected][formaPagemento]
      if (metodos !== undefined) {
        options = Object.keys(metodos).filter(e => e !== 'avaible')

        if (this.metodoPagamento === '' || !metodos[this.metodoPagamento]) {
          this.metodoPagamento = [options[0]]
        }

        this.parametro = metodos[this.metodoPagamento]
      }
      return options
    },
    setMetodoPagamento2() {
      let options2 = []
      const { credores, credorPos } = this
      const { parametros } = credores[credorPos]
      const { selected } = this.keys2
      const metodos = parametros[selected][this.formaPagemento2]
      if (metodos !== undefined) {
        options2 = Object.keys(metodos).filter(e => e !== 'avaible')

        if (this.metodoPagamento2 === '' || !metodos[this.metodoPagamento2]) {
          this.metodoPagamento2 = [options2[0]]
        }
        this.parametro2 = metodos[this.metodoPagamento2]
      }

      return options2
    },
    setFields() {
      const { parametro } = this
      const fields = Object.keys(parametro).filter(e => e !== 'avaible' && e !== 'label')
      return fields
    },
    setItens() {
      const { parametro } = this
      return [parametro]
    },
    setItens2() {
      return [this.parametro2]
    },
    credorOptions() {
      const keys = []
      const { credores } = this
      credores.map(credor => {
        keys.push(credor.nome)
      })
      return keys
    },
    credorOptions2() {
      const keys = []
      const { credores } = this
      credores.map(credor => {
        keys.push(credor.nome)
      })
      return keys
    },
  },
  watch: {
    credorSelected(n) {
      const { credores } = this
      credores.map((credor, index) => {
        if (n === credor.nome) {
          this.credorPos = index
        }
      })
    },
  },
  created() {
    this.setCredorPos()
    this.obs = this.credores[0].parametros.default.observacoes
    this.getInfoCredor()
  },
  methods: {
    async setCredorPos() {
      const { credores } = this
      const credorNotParams = () => {
        this.$swal({
          title: 'Impossível negociar!',
          text: 'Este credor não possui paramêtros',
          icon: 'error',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        })
        this.avaible = false
      }
      if (credores.length > 0) {
        const credor = this.credores[0]
        if (credor.parametros) {
          this.credorPos = 0
          this.avaible = true
        } else {
          credorNotParams()
        }
      } else {
        this.avaible = true
        this.credorSelected = this.credores[0].nome
      }
    },
    repeateParametro() {
      this.moreParametro = true
    },
    ocultParametro() {
      this.moreParametro = false
    },
    getItem(item) {
      this.selectedCredor = item.id
      this.getInfoCredores()
    },
    getInfoCredor() {
      if (this.credores.length === 1) {
        axios.get(`/api/v1/credores/edit/${this.credores[0].id}`, {
          headers: {
            Authorization: 'Bearer '.concat(localStorage.getItem('token')),
            accept: 'application/json',
          },
        })
          .then(res => {
            this.infoCredor = {
              negativacao: res.data.dados.credores.negativacao === true ? 'Sim' : 'Não',
              prazoRemessa: res.data.dados.credores.prazo_remessa,
              gerarNumeroOperacao: res.data.dados.credores.gerar_numero_operacao === true ? 'Sim' : 'Não',
              razaoSocial: res.data.dados.credores.parametros_config.default.convenio.nome_beneficiario,
              cartao: res.data.dados.credores.parametros_config.default.opcao_cartao,
              convenio: res.data.dados.credores.parametros_config.default.convenio,
              correcao_monetario: res.data.dados.credores.parametros_config.default.correcao_monetario,
              ativo_portal: res.data.dados.credores.parametros_config.default.ativo_portal,
              observacoes: res.data.dados.credores.parametros_config.default.observacoes,
              comissao_fixa: res.data.dados.credores.parametros_config.default.comissao_fixa,
              comissao_baixa_credor: res.data.dados.credores.parametros_config.default.comissao_baixa_credor,
              base_parcela: res.data.dados.credores.parametros_config.default.base_parcela,
            }
          })
      }
    },

    getInfoCredores() {
      const idCredor = this.selectedCredor
      axios.get(`/api/v1/credores/edit/${idCredor}`, {
        headers: {
          Authorization: 'Bearer '.concat(localStorage.getItem('token')),
          accept: 'application/json',
        },
      })
        .then(res => {
          this.infoCredor = {
            negativacao: res.data.dados.credores.negativacao === true ? 'Sim' : 'Não',
            prazoRemessa: res.data.dados.credores.prazo_remessa,
            gerarNumeroOperacao: res.data.dados.credores.gerar_numero_operacao === true ? 'Sim' : 'Não',
            razaoSocial: res.data.dados.credores.parametros_config.default.convenio.nome_beneficiario,
            cartao: res.data.dados.credores.parametros_config.default.opcao_cartao,
            convenio: res.data.dados.credores.parametros_config.default.convenio,
            correcao_monetario: res.data.dados.credores.parametros_config.default.correcao_monetario,
            ativo_portal: res.data.dados.credores.parametros_config.default.ativo_portal,
            observacoes: res.data.dados.credores.parametros_config.default.observacoes,
            comissao_fixa: res.data.dados.credores.parametros_config.default.comissao_fixa,
            comissao_baixa_credor: res.data.dados.credores.parametros_config.default.comissao_baixa_credor,
            base_parcela: res.data.dados.credores.parametros_config.default.base_parcela,
          }
        })
    },
  },
}
</script>
