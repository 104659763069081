<template>
  <div>
    <b-card>
      <b-overlay
        :show="load"
        variant="transparent"
      >
        <b-row>
          <b-col
            v-for="(item, i) in cards"
            :key="item.icon"
            xl="2"
            sm="3"
            @click="itemClick(i)"
          >
            <b-media no-body>
              <b-media-aside

                class="mr-2"
              >
                <b-avatar
                  size="48"
                  :variant="item.color"
                >
                  <feather-icon
                    size="24"
                    :icon="item.icon"
                  />
                </b-avatar>
              </b-media-aside>
              <b-media-body class="my-1">
                <h4 class="font-weight-bolder mb-0">
                  {{ item.title }}
                </h4>
                <b-card-text class="font-small-3 mb-0">
                  {{ item.subtitle }}
                </b-card-text>
              </b-media-body>
            </b-media>
          </b-col>
        </b-row>
      </b-overlay>
    </b-card>

    <b-tab
      v-if="itemClidado === 0"
    >
      <b-table
        responsive
        striped
        bordered
        :fields="fieldsChamadas"
        :items="ligacoes"
        :per-page="perPage"
        :current-page="currentPage"
        :sort-by.sync="sortChamada"
        :sort-desc.sync="sortDescChamada"
      >

        <template #cell(data_hora)="data">
          {{ formatarDataHora(data.item.data_hora) }}
        </template>
      </b-table>
      <b-pagination
        v-model="currentPage"
        :total-rows="ligacoes.length"
        :per-page="perPage"
        align="center"
        size="md"
        class="my-0"
      />
    </b-tab>

    <b-col
      v-if="itemClidado === 1"
    >
      <b-table
        responsive
        striped
        :items="itemsSMS"
        :fields="fieldsSMS"
        :per-page="perPage"
        :current-page="currentPage"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
      >
        <template #cell(show_details)="row">
          <b-form-checkbox
            v-model="row.detailsShowing"
            class="custom-control-primary"
            @change="row.toggleDetails"
          >
            <span class="vs-checkbox">
              <span class="vs-checkbox--check">
                <i class="vs-icon feather icon-check" />
              </span>
            </span>
            <span class="vs-label">{{ row.detailsShowing ? 'Esconder' : 'Descrição' }}</span>
          </b-form-checkbox>
        </template>

        <template #row-details="row">
          <b-card>
            <b-row class="mb-2">
              <b-col
                md="12"
                class="mb-1"
              >
                <div
                  v-for="(key, i) in JSON.parse(row.item.observacao)"
                  :key="key"
                >
                  <span> <strong> {{ i }}: </strong> {{ key }}</span>
                </div>
              </b-col>
            </b-row>

            <b-button
              size="sm"
              variant="outline-secondary"
              @click="row.toggleDetails"
            >
              Esconder Descrição
            </b-button>
          </b-card>
        </template>

        <template #cell(data_envio)="data">
          {{ formatarDataHora(data.item.data_envio) }}
        </template>
      </b-table>
      <b-pagination
        v-model="currentPage"
        :total-rows="itemsSMS.length"
        :per-page="perPage"
        align="center"
        size="md"
        class="my-0"
      />
    </b-col>

    <b-col
      v-if="itemClidado === 2"
    >
      <b-table
        responsive
        striped
        :items="itemsEmails"
        :fields="fieldsEmails"
        :per-page="perPage"
        :current-page="currentPage"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
      >
        <template #cell(data_envio)="data">
          {{ formatarDataHora(data.item.data_envio) }}
        </template>

      </b-table>
      <b-pagination
        v-model="currentPage"
        :total-rows="itemsEmails.length"
        :per-page="perPage"
        align="center"
        size="md"
        class="my-0"
      />

    </b-col>
    <b-col
      v-if="itemClidado === 3"
    >
      <b-table
        responsive
        striped
        :items="itemsWhatsApp"
        :fields="fieldsWhatsApp"
        :per-page="perPage"
        :current-page="currentPage"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
      >
        <template #cell(show_details)="row">
          <b-form-checkbox
            v-model="row.detailsShowing"
            class="custom-control-primary"
            @change="row.toggleDetails"
          >
            <span class="vs-checkbox">
              <span class="vs-checkbox--check">
                <i class="vs-icon feather icon-check" />
              </span>
            </span>
            <span class="vs-label">{{ row.detailsShowing ? 'Esconder' : 'Descrição' }}</span>
          </b-form-checkbox>
        </template>

        <template #row-details="row">
          <b-card>
            <b-row class="mb-2">
              <b-col
                md="12"
                class="mb-1"
              >
                <strong>Mensagem Enviada:</strong>
                <br>
                <span>{{ row.item.texto_sms }}</span>
              </b-col>
            </b-row>

            <b-button
              size="sm"
              variant="outline-secondary"
              @click="row.toggleDetails"
            >
              Esconder Descrição
            </b-button>
          </b-card>
        </template>

        <template #cell(telefone)="data">
          {{ phoneLabel(data.item.telefone) }}
        </template>

        <template #cell(cpf_cnpj)="data">
          {{ cpfCNPJ(data.item.cpf_cnpj) }}
        </template>

        <template #cell(data_envio)="data">
          {{ formatarDataHora(data.item.data_envio) }}
        </template>
      </b-table>
      <b-pagination
        v-model="currentPage"
        :total-rows="itemsWhatsApp.length"
        :per-page="perPage"
        align="center"
        size="md"
        class="my-0"
      />
    </b-col>

  </div>
</template>

<script>

// IMmportando dependecias de estilos
import {
  BRow,
  BCol,
  VBPopover,
  BCard,
  BAvatar,
  BMediaAside,
  BMediaBody,
  BCardText,
  BTable,
  BPagination,
  BFormCheckbox,
  BButton,
  BMedia,
  BOverlay,

} from 'bootstrap-vue'

import { mapState } from 'vuex'

// Importando mecanismo de requisições
import axios from '@/../axios-auth'

export default {
  components: {
    BRow,
    BCol,
    BTable,
    BPagination,
    BFormCheckbox,
    // BBadge,
    // BLink,
    BCard,
    BAvatar,
    BMediaAside,
    BMediaBody,
    BCardText,
    BButton,
    BMedia,
    BOverlay,
  },
  directives: {
    'b-popover': VBPopover,
  },
  data() {
    return {
      itemsSMS: [],
      itemsEmails: [],
      itemsWhatsApp: [],

      itemClidado: null,
      tabelaClicada: null,
      ligacoes: [],
      itemSelected: [],
      userData: localStorage.getItem('funcao'),
      items: [],
      perPage: 15,
      currentPage: 1,
      cards: [],
      fieldsChamadas: [
        {
          key: 'data_hora', label: 'DATA', class: 'text-center',
        },
        {
          key: 'telefone', label: 'TELEFONE', class: 'text-center',
        },
        { key: 'resultado', label: 'RESULTADO', class: 'text-center' },
        {
          key: 'origem_ligacao', label: 'ORIGEM', class: 'text-center', sortable: true,
        },
      ],

      fieldsSMS: [
        { key: 'show_details', label: 'Mostrar Detalhes', class: 'text-center' },
        { key: 'credor_nome', label: 'Credor', class: 'text-center' },
        { key: 'data_envio', label: 'data envio', class: 'text-center' },
      ],

      fieldsEmails: [
        { key: 'credor_nome', label: 'Credor', class: 'text-center' },
        { key: 'email', label: 'email', class: 'text-center' },
        { key: 'data_envio', label: 'data envio', class: 'text-center' },
      ],

      fieldsWhatsApp: [
        { key: 'show_details', label: 'Mostrar Detalhes', class: 'text-center' },
        { key: 'credor_nome', label: 'Credor', class: 'text-center' },
        { key: 'campanhas_id', label: 'ID Campanha', class: 'text-center' },
        { key: 'telefone', label: 'Telefone', class: 'text-center' },
        { key: 'cpf_cnpj', label: 'CPF/CNPJ', class: 'text-center' },
        { key: 'data_envio', label: 'data envio', class: 'text-center' },
        { key: 'username', label: 'usuário', class: 'text-center' },
      ],
      isCredor: localStorage.getItem('credores'),
      totaisLigacoes: 0,
      totaisSMS: 0,
      totaisEmails: 0,
      totaisWhatsApp: 0,
      sortBy: 'data_envio',
      sortDesc: true,
      sortChamada: 'data_hora',
      sortDescChamada: true,
      load: false,
    }
  },
  computed: {
    ...mapState({
      cliente: state => {
        const { infoCliente } = state.negociacao
        return infoCliente
      },
    }),

  },
  async created() {
    await this.getStatusOperacao()
    this.setCards()
  },
  methods: {
    async itemClick(data) {
      if (data === 0) {
        this.itemClidado = 0
      }
      if (data === 1) {
        this.itemClidado = 1
      }
      if (data === 2) {
        this.itemClidado = 2
      }
      if (data === 3) {
        this.itemClidado = 3
      }
    },

    async getStatusOperacao() {
      this.load = true
      const { cpf_cnpj: doc } = this.cliente
      await axios.get(`api/v1/dashboard/credor/info/cliente/${doc}`, {
        headers: {
          Accept: 'application/json',
          Authorization: 'Bearer '.concat(localStorage.getItem('token')),
        },
      }).then(res => {
        this.load = false
        this.ligacoes = res.data.dados.ligacoes
        this.totaisLigacoes = this.ligacoes.length

        this.itemsSMS = res.data.dados.sms
        this.totaisSMS = this.itemsSMS.length

        this.itemsEmails = res.data.dados.email
        this.totaisEmails = this.itemsEmails.length

        const whatsappDuplicados = res.data.dados.whatsapp
        this.itemsWhatsApp = whatsappDuplicados.filter((obj, index, array) => array.findIndex(t => t.id === obj.id) === index)
        this.totaisWhatsApp = this.itemsWhatsApp.length
      })
    },

    async setCards() {
      const cards = [
        {
          color: 'light-info',
          customClass: '',
          icon: 'ActivityIcon',
          subtitle: 'LIGAÇÕES',
          title: this.totaisLigacoes,
        },
        {
          color: 'light-danger',
          customClass: '',
          icon: 'AlertTriangleIcon',
          subtitle: 'SMS',
          title: this.totaisSMS,
        },
        {
          color: 'light-primary',
          customClass: '',
          icon: 'ClockIcon',
          subtitle: 'EMAILS',
          title: this.totaisEmails,
        },
        {
          color: 'light-info',
          customClass: '',
          icon: 'WhatsAppIcon',
          subtitle: 'WHATSAPP',
          title: this.totaisWhatsApp,
        },
      ]
      this.cards = cards
    },

    formatarDataHora(dados) {
      const recebida = dados
      const nova = recebida.substr(0, 10)
      const hora = recebida.substr(11, 18)
      const b = nova.split('-')
      const dataBr = `${b[2]}/${b[1]}/${b[0]}`
      const dataFinal = `${dataBr} ${hora}`
      return dataFinal
    },
  },
}
</script>
