<template>
  <div>
    <div class="d-flex justify-content-start">
      <b-avatar
        size="70px"
        :text="getInitials(headerInfoCliente.nome)"
      />
      <div class="row d-flex flex-column ml-1">
        <div class="mb-1">
          <h4 class="mb-0 text-wrap">
            {{ headerInfoCliente.nome }}
          </h4>
          <!-- arranjo tecnico para a função copiar funcionar no servidor -->
          <div class="sr-only">
            <input
              id="text-box"
              type="text"
              size="20"
              :value="headerInfoCliente.cpf_cnpj"
            >
          </div>
          <span class="card-text mr-1 text-wrap"><strong>CPF/CNPJ:</strong> {{ cpfCNPJ(headerInfoCliente.cpf_cnpj) }}</span>
          <feather-icon
            icon="CopyIcon"
            class="mr-60 cursor-pointer"
            size="15"
            variant="gradient-info"
            @click="copiar()"
          />
          <feather-icon
            v-if="statusFavorito.status"
            icon="StarIcon"
            class="ml-2 cursor-pointer star"
            size="15"
            @click="alterarStatusFavorito()"
          />
          <feather-icon
            v-else
            icon="StarIcon"
            class="ml-2 cursor-pointer"
            size="15"
            @click="favoritarCliente()"
          />
          <span
            class="align-middle"
          />
          <br>
          <label for="">
            <strong>CREDOR:</strong>
            <span
              v-for="(credornome, i) in headerInfoCliente.credores"
              :key="i"
              class="card-text mr-1 text-wrap"
            >
              <b-badge
                pill
                variant="primary text-wrap"
              >
                <span> {{ credornome.nome }} </span> <br>
              </b-badge>
            </span>
          </label>
          <h4>
            <b-badge variant="light-warning text-wrap">
              Código: {{ headerInfoCliente.id }}
            </b-badge>
          </h4>
          <h4 v-if="headerInfoCliente.perfil !== null">
            Perfil do Cliente:
            <b-badge :variant="badgeVariantPerfilCliente(headerInfoCliente.perfil)">
              {{ headerInfoCliente.perfil }}
            </b-badge>
          </h4>
          <!-- <span>{{ dt.localizacao }}</span> -->

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  BAvatar,
  BBadge,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import axios from '@/../axios-auth'

export default {
  components: {
    BAvatar,
    BBadge,
  },
  props: {
    headerInfoCliente: {
      type: Object,
      required: true,
    },
    updateHeader: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      statusFavorito: {
        status: null,
        id: null,
      },
    }
  },
  watch: {
    statusFavorito(n) {
      this.statusFavorito.status = n.status
    },
  },
  created() {
    this.getStatusFavorito()
  },
  methods: {
    // função para copiar para area de transferencia do usuario utilizando metodos antigos
    // pelo fato do servidor não possuir certificado https. Refatorar quando estiver com o certificado https
    copiar() {
      // eslint-disable-next-line
      let testingCodeToCopy = document.querySelector('#text-box')
      testingCodeToCopy.setAttribute('type', 'text')
      testingCodeToCopy.select()

      try {
        document.execCommand('copy')
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Copiado',
            icon: 'CheckIcon',
            text: 'Copiado com sucesso',
            variant: 'success',
          },
        },
        {
          position: 'top-center',
        })
        /* unselect the range */
        testingCodeToCopy.setAttribute('type', 'hidden')
        window.getSelection().removeAllRanges()
      } catch (err) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'erro',
            icon: 'XIcon',
            text: 'Erro ao copiar',
            variant: 'danger',
          },
        },
        {
          position: 'top-center',
        })
      }
    },
    favoritarCliente() {
      if (this.statusFavorito.status === null) {
        const body = {
          cliente_id: this.$route.params.id,
          status: true,
        }
        axios.post('api/v1/cliente_favorito/store', body, {
          headers: {
            Authorization: 'Bearer '.concat(localStorage.getItem('token')),
            accept: 'application/json',
          },
        })
          .then(res => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Cliente Favoritado com sucesso!',
                icon: 'CheckIcon',
                text: res.data.mensagem,
                variant: 'success',
              },
            },
            {
              position: 'top-center',
            })
            this.getStatusFavorito()
            this.updateHeader()
          })
          .catch(() => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Opss!',
                icon: 'CheckIcon',
                text: 'Algo deu errado',
                variant: 'danger',
              },
            },
            {
              position: 'top-center',
            })
          })
      } else {
        this.alterarStatusFavorito()
      }
    },
    getStatusFavorito() {
      const body = {
        cliente_id: this.$route.params.id,
        usuario_empresas_id: parseInt(localStorage.getItem('usuarioEmpresas')),
      }
      axios.post('api/v1/cliente_favorito/listar/unico', body, {
        headers: {
          Authorization: 'Bearer '.concat(localStorage.getItem('token')),
          accept: 'application/json',
        },
      })
        .then(res => {
          if (res.data.dados.length) {
            this.statusFavorito = {
              status: res.data.dados[0].status,
              id: res.data.dados[0].id,
            }
          }
        })
    },
    alterarStatusFavorito() {
      let body
      if (this.statusFavorito.status) { body = { status: false } }
      if (!this.statusFavorito.status) { body = { status: true } }
      axios.put(`api/v1/cliente_favorito/update/status/${this.statusFavorito.id}`, body, {
        headers: {
          Authorization: 'Bearer '.concat(localStorage.getItem('token')),
          accept: 'application/json',
        },
      })
        .then(res => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Alterado com sucesso!',
              icon: 'CheckIcon',
              text: res.data.mensagem,
              variant: 'success',
            },
          },
          {
            position: 'top-center',
          })
          this.getStatusFavorito()
          this.updateHeader()
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Opss!',
              icon: 'CheckIcon',
              text: 'Algo deu errado',
              variant: 'danger',
            },
          },
          {
            position: 'top-center',
          })
        })
    },
  },
}
</script>
