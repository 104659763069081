<template>
  <div>
    <b-row
      v-if="!storingAcordo"
      class="mb-1"
    >
      <b-col>
        <b-button
          v-if="!isSimulacao || isSupervisor"
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          class="mr-1 float-right"
          variant="primary"
          @click="fecharAcordo"
        >
          Fechar Acordo
        </b-button>
        <b-button
          v-if="isExecutive || isSupervisor"
          variant="outline-primary"
          class="mr-1 float-right"
          @click="simularAcordo"
        >
          Simular Acordo
        </b-button>
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          class="mr-1 float-right"
          variant="outline-warning"
          @click="showDemonstrativo"
        >
          Demonstrativo
        </b-button>
        <b-button
          v-show="isSimulacao"
          v-if="isExecutive || isSupervisor"
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          class="mr-1 float-right"
          variant="warning"
          @click="createProposta"
        >
          Proposta do Cliente
        </b-button>
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <b-row>
          <b-col class="text-center">
            <h4>{{ progressMessage }}</h4>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-progress
              :key="bar.variant"
              animated
              :value="bar.value"
              :variant="bar.variant"
              :class="'progress-bar-' + bar.variant"
            />
          </b-col>
        </b-row>
      </b-col>
    </b-row>

    <b-modal
      id="modal-demonstrativo"
      ref="modal-demonstrativo"
      size="xl"
      title=""
    >
      <b-row>
        <b-col>
          <iframe
            id="myFrame"
            :src="urlDemonstrativo"
          />
        </b-col>
      </b-row>

      <template #modal-footer>
        <b-row>
          <b-col>
            <div class="w-100">
              <b-button
                v-show="!isSimulacao"
                variant="primary"
                size="md"
                class="float-right"
                @click="sendDemonstrativo"
              >
                Enviar para cliente
              </b-button>
            </div>
          </b-col>

          <b-col
            v-if="!isExecutive"
          >
            <div class="w-100">
              <b-button
                variant="warning"
                size="md"
                class="float-right"
                @click="sendPropostaEmail"
              >
                Enviar para Credor
              </b-button>
            </div>
          </b-col>
        </b-row>
      </template>
    </b-modal>
  </div>
</template>

<script>
import {
  BButton,
  BCol,
  BModal,
  BProgress,
  BRow,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import md5 from 'crypto-js/md5'

import qs from 'qs'
import { mapGetters, mapState } from 'vuex'
import api from '@/../axios-auth'

import { ping } from '../../../Discador/functions'

export default {
  components: {
    BRow,
    BCol,
    BButton,
    BModal,
    BProgress,
  },
  directives: {
    Ripple,
  },
  props: {
    toggleNegociar: {
      type: Function,
      required: true,
    },
    updateTabs: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      fileDesmonstrativo: null,
      urlDemonstrativo: null,
      bar: { variant: '', value: 0 },
      progressMessage: '',
      storingAcordo: false,
    }
  },
  computed: {
    isSupervisor() {
      const { funcao } = localStorage
      return funcao === 'Supervisor(a) Cobrança'
    },
    ...mapState({
      cliente: state => {
        const { infoCliente } = state.negociacao
        return infoCliente
      },
      emailsCliente: state => {
        const { emails } = state.negociacao
        return emails
      },
      credorSelected: state => {
        const { credorSelected } = state.negociar
        return credorSelected
      },
      parametroDefault: state => state.negociar.parametroDefault,
      isSimulacao: state => state.negociar.isSimulacao,
      getRemuneracao: state => state.negociar.getRemuneracao,

      dataEntrada: state => state.negociar.negociacao.dataEntrada,
      jurosPorcento: state => state.negociar.negociacao.juros,
      multaPorcento: state => state.negociar.negociacao.entrada,
      honorariosPorcento: state => state.negociar.negociacao.entrada,
      descontoPorcento: state => state.negociar.negociacao.entrada,
      itemSelected: state => state.negociar.operacoesSelected,
      formaPagamento: state => state.negociar.formaPagamento,
      nParcelas: state => state.negociar.nParcelas,
      entrada: state => state.negociar.entrada,

      totalJuros: state => state.negociar.totais.totalJuros,
      totalMulta: state => state.negociar.totais.totalMulta,
      totalHonorario: state => state.negociar.totais.totalHonorario,
      totalDesconto: state => state.negociar.totais.totalDesconto,
      valorTotal: state => state.negociar.totais.valorTotal,
      totalNominal: state => state.negociar.totais.totalNominal,
    }),
    ...mapGetters({
      setDataVencimento: 'negociar/setDataVencimento',
    }),
    isExecutive() {
      // eslint-disable-next-line camelcase
      const { executivo_id: executivo_cobranca_id } = localStorage
      // eslint-disable-next-line camelcase
      if (!executivo_cobranca_id || executivo_cobranca_id === 'false') {
        return false
      }
      return true
    },
  },
  methods: {
    async showDemonstrativo() {
      // 'negociar/getBodyAcordo'
      const body = await this.$store.getters['negociar/getBodyAcordo']()
      if (body) {
        body.simulacao = true
        body.acordo.cliente_id = this.$route.params.id
      }
      this.$swal({
        title: 'Selecione modelo do demonstrativo',
        icon: 'info',
        showCancelButton: true,
        confirmButtonText: 'Resumido',
        cancelButtonText: 'Detalhado',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(async result => {
        if (result.value) {
          const demonstrativo = await api.post('api/v1/acordo/viewDemoSimples', body, {
            responseType: 'arraybuffer',
          }).then(res => {
            const file = new File([res.data], 'demonstrativo.pdf', {
              type: 'application/pdf',
            })
            return file
          })
          this.fileDesmonstrativo = demonstrativo
          this.urlDemonstrativo = URL.createObjectURL(demonstrativo)
          this.$refs['modal-demonstrativo'].show()
        } else if (result.dismiss === 'cancel') {
          const demonstrativo = await api.post('api/v1/acordo/viewDemoDetalhado', body, {
            responseType: 'arraybuffer',
          }).then(res => {
            const file = new File([res.data], 'demonstrativo.pdf', {
              type: 'application/pdf',
            })
            return file
          })
          this.fileDesmonstrativo = demonstrativo
          this.urlDemonstrativo = URL.createObjectURL(demonstrativo)
          this.$refs['modal-demonstrativo'].show()
        }
      })
    },
    sendDemonstrativo() {
      const { fileDesmonstrativo } = this
      const { nome } = this.cliente
      const body = new FormData()

      body.append('name', `${nome}`)
      body.append('cc', JSON.stringify(this.emailsCliente.map(email => ({
        name: this.fisrtLastName(nome), address: email,
      }))))
      body.append('credor', this.credorSelected.nome)
      body.append('demonstrativo', fileDesmonstrativo)

      const configEmail = {
        method: 'post',
        url: `${process.env.VUE_APP_ROOT_HUBLE}/demonstrativo`,
        data: body,
      }

      api(configEmail).then(() => {
        this.$toast(
          {
            component: ToastificationContent,
            props: {
              title: 'Concluído',
              icon: 'CheckIcon',
              text: 'Simulação enviada para o cliente',
              variant: 'success',
            },
          },
          {
            position: 'top-center',
          },
        )
      })
    },
    async sendPropostaEmail() {
      const { fileDesmonstrativo } = this
      const { nome } = this.cliente

      const representantes = await api.get(`api/v1/credor_contatos/${this.credorSelected.id}`).then(res => res.data.dados)

      const to = representantes.filter(e => e.relatorios.filter(i => i.title === 'Proposta').length > 0).map(item => ({
        name: item.nome,
        address: item.email,
      }))

      const body = new FormData()
      body.append('name', `${nome}`)
      body.append('from', JSON.stringify({ name: localStorage.userName, address: localStorage.userEmail }))
      body.append('to', JSON.stringify(to))
      body.append('credor', this.credorSelected.nome)
      body.append('demonstrativo', fileDesmonstrativo)

      body.append('dataProposta', this.americaDate(this.dataEntrada))
      body.append('total', `R$ ${this.totalNegociado}`)

      body.append('nParcelas', this.nParcelas)
      body.append('getRemuneracao', this.getRemuneracao)
      body.append('cpfCliente', this.cliente.cpf_cnpj)

      body.append('table', `
        <table class="MsoNormalTable" border="1" cellspacing="0" cellpadding="0">
                    <tbody>
                    <tr>
                      <td width="97" style="width:72.55pt; padding:7.5pt 7.5pt 7.5pt 7.5pt">
                        <p class="MsoNormal" align="center" style="text-align:center"><b><span style="font-family: Montserrat, -apple-system, 'Segoe UI', sans-serif;">Juros</span></b></p>
                      </td>
                      <td width="85" style="width:63.8pt; padding:7.5pt 7.5pt 7.5pt 7.5pt">
                        <p class="MsoNormal" align="center" style="text-align:center"><b><span style="font-family: Montserrat, -apple-system, 'Segoe UI', sans-serif;">Multa</span></b></p>
                      </td>
                      <td width="94" style="width:70.85pt; padding:7.5pt 7.5pt 7.5pt 7.5pt">
                        <p class="MsoNormal" align="center" style="text-align:center"><b><span style="font-family: Montserrat, -apple-system, 'Segoe UI', sans-serif;">Honorário</span></b></p>
                      </td>
                      <td width="95" style="width:70.9pt; padding:7.5pt 7.5pt 7.5pt 7.5pt">
                        <p class="MsoNormal" align="center" style="text-align:center"><b><span style="font-family: Montserrat, -apple-system, 'Segoe UI', sans-serif;">Desconto</span></b></p>
                      </td>
                    </tr>
                    <tr style="height:7.1pt">
                      <td width="97" style="width:72.55pt; padding:7.5pt 7.5pt 7.5pt 7.5pt; height:7.1pt">
                        <p class="MsoNormal" align="center" style="text-align:center"><b><span style="font-size:9.0pt; font-family: Montserrat, -apple-system, 'Segoe UI', sans-serif;">${this.jurosPorcento}%</span></b></p>
                      </td>
                      <td width="85" style="width:63.8pt; padding:7.5pt 7.5pt 7.5pt 7.5pt; height:7.1pt">
                        <p class="MsoNormal" align="center" style="text-align:center"><b><span style="font-size:9.0pt; font-family: Montserrat, -apple-system, 'Segoe UI', sans-serif;">${this.multaPorcento}%</span></b></p>
                      </td>
                      <td width="94" style="width:70.85pt; padding:7.5pt 7.5pt 7.5pt 7.5pt; height:7.1pt">
                        <p class="MsoNormal" align="center" style="text-align:center"><b><span style="font-size:9.0pt; font-family: Montserrat, -apple-system, 'Segoe UI', sans-serif;">${this.honorariosPorcento}%</span></b></p>
                      </td>
                      <td width="95" style="width:70.9pt; padding:7.5pt 7.5pt 7.5pt 7.5pt; height:7.1pt">
                        <p class="MsoNormal" align="center" style="text-align:center"><b><span style="font-size:9.0pt; font-family: Montserrat, -apple-system, 'Segoe UI', sans-serif;">${this.descontoPorcento}%</span></b></p>
                      </td>
                    </tr>
                    <tr>
                      <td width="97" style="width:72.55pt; padding:7.5pt 7.5pt 7.5pt 7.5pt">
                        <p class="MsoNormal" align="center" style="text-align:center"><span style="font-family: Montserrat, -apple-system, 'Segoe UI', sans-serif;">${this.valorBr(parseFloat(this.totalJuros), true)}</span></p>
                      </td>
                      <td width="85" style="width:63.8pt; padding:7.5pt 7.5pt 7.5pt 7.5pt">
                        <p class="MsoNormal" align="center" style="text-align:center"><span style="font-family: Montserrat, -apple-system, 'Segoe UI', sans-serif;">${this.valorBr(parseFloat(this.totalMulta), true)}</span></p>
                      </td>
                      <td width="94" style="width:70.85pt; padding:7.5pt 7.5pt 7.5pt 7.5pt">
                        <p class="MsoNormal" align="center" style="text-align:center"><span style="font-family: Montserrat, -apple-system, 'Segoe UI', sans-serif;">${this.valorBr(parseFloat(this.totalHonorario), true)}</span></p>
                      </td>
                      <td width="95" style="width:70.9pt; padding:7.5pt 7.5pt 7.5pt 7.5pt">
                        <p class="MsoNormal" align="center" style="text-align:center"><span style="font-family: Montserrat, -apple-system, 'Segoe UI', sans-serif;">${this.valorBr(parseFloat(this.totalDesconto), true)}</span></p>
                      </td>
                    </tr>
                    </tbody>
                  </table>
      `)

      // eslint-disable-next-line no-unused-vars
      const configEmail = {
        method: 'post',
        url: 'http://192.168.254.219:3000/api/v1/proposta-credor',
        data: body,
      }

      api(configEmail).then(() => {
        this.$toast(
          {
            component: ToastificationContent,
            props: {
              title: 'Concluído',
              icon: 'CheckIcon',
              text: 'Proposta enviada para o credor',
              variant: 'success',
            },
          },
          {
            position: 'top-center',
          },
        )
      })
    },
    async simularAcordo() {
      const CredorId = this.credorSelected.id
      const operacoesIds = this.itemSelected.map(item => `${item.numero_operacao} - ${item.data_vencimento}`)
      const {
        totalNominal,
        totalJuros,
        totalMulta,
        totalHonorario,
        totalDesconto,
        valorTotal,
      } = this.$store.state.negociar.totais

      await ping().catch(() => {})
      const { negociacao } = this.$store.state.negociar
      const valorParcela = this.$store.getters['negociar/valorParcela']
      const lastCalls = JSON.parse(localStorage.getItem('lastCalls'))
      const parametrosContato = lastCalls[this.cliente.id]

      const body = {
        descricao: `=-=-=-=-=-=-= <b>SIMULAÇÃO DE ACORDO</b> =-=-=-=-=-=-=-= <br>
        <b>Operacoes:</b> ${operacoesIds.toString()}<br>
        <b>Valor nominal:</b> ${totalNominal}<br>
        <b>Juros:</b> ${negociacao.juros}% ${totalJuros}<br>
        <b>Multa:</b> ${negociacao.multa}% ${totalMulta}<br>
        <b>Honorario:</b> ${negociacao.honorarios}% ${totalHonorario}<br>
        <b>Desconto:</b> ${negociacao.desconto}% ${totalDesconto}<br>
        <b>Entrada:</b> ${valorTotal}<br>
        <b>Quantidade de parcelas:</b> ${negociacao.nParcelas}<br>
        <b>Valor Parcela:</b> ${valorParcela}<br>
        <b>Data vencimento: ${negociacao.dataEntrada ? this.formatTimezone(negociacao.dataEntrada) : ''}</b>
        `,
        cliente_id: this.$route.params.id,
        // eslint-disable-next-line radix
        usuario_empresas_id: parseInt(localStorage.getItem('userId')),
        // motivo_evento_id: this.alert ? 9 : 20,
        motivo_evento_id: 20,
        credores_id: CredorId,
        parametros_contato: parametrosContato,
      }

      api.post('api/v1/eventos/store', body)
        .then(async () => {
          await this.$store.dispatch('negociacao/getInfoCliente', this.$route.params.id)
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Sucesso',
              icon: 'BellIcon',
              text: 'Evento de simulação concluído',
              variant: 'success',
            },
          })
        })
        .catch(error => {
          this.$swal({
            title: 'Error',
            text: error.response.data.mensagem,
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
        })
    },

    async gerarBoleto(acordo) {
      const {
        dataEntrada,
      } = this

      const {
        codigo_banco: banco,
        convenio,
      } = this.parametroDefault.convenio

      const {
        cpf_cnpj,
        nome,
        endereco,
      } = this.cliente

      const dataVencimento = dataEntrada.replace('/')

      const valorBoleto = parseFloat(acordo.parcelas[0].valor_nominal) * 100
      const body = qs.stringify({
        token: '43241324',
        banco,
        convenio,
        numero_acordo: acordo.id,
        cpf_cnpj,
        nome,
        endereco,
        bairro: 'Três Poderes',
        cidade: 'IMPERATRIZ',
        uf: 'MA',
        cep: '65901150',
        data_vencimento: dataVencimento,
        valor: valorBoleto,
      })

      const config = {
        method: 'post',
        url: 'http://192.168.254.205:9000/boleto',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        data: body,
      }

      const boleto = await api(config).then(res => res.data)
      return boleto.numero_linha_digitavel ? boleto : null
    },
    async gerarPdf(boleto, acordo) {
      const [parcela] = acordo.parcelas
      const { usuarioEmpresas: userId } = localStorage

      const {
        codigo_barras,
        nosso_numero,
        numero_linha_digitavel,
        pix_copia_cola,
        txid,
      } = boleto

      const { convenio: cv } = this.parametroDefault

      const { nome_beneficiario } = cv
      const { cnpj_beneficiario } = cv
      const { endereco_beneficiario } = cv
      const { carteira } = cv
      const { agencia } = cv
      const banco = cv.codigo_banco
      const { convenio } = cv

      const data = {
        codigo_banco: banco,
        convenio,
        linha_digitavel: this.linhaDigitavel(numero_linha_digitavel),
        codigo_barra: codigo_barras,
        chave_pix: pix_copia_cola,
        nome_beneficiario,
        cnpj_beneficiario,
        endereco_beneficiario,
        vencimento: this.setDataVencimento(1),
        valor: acordo.parcelas[0].valor_nominal,
        nosso_numero,
        carteira,
        agencia,
        data_documento: this.americaDate(this.setDataVencimento(1)),
        numero_documento: '2116654',
        desconto: '0',
        deducoes: '0',
        juros: '0',
        acrescimos: '0',
        // valor_cobrado: 3.50,
        valor_cobrado: acordo.parcelas[0].valor_nominal,
        nome_pagador: this.cliente.nome,
        cpf_pagador: this.cpfCNPJ(this.cliente.cpf_cnpj),
        nome_avaliador: this.credorSelected.nome,
        codigo_credor: this.credorSelected.id,
        parcelas_id: parcela.id,
        id_pix: txid,
        usuario_empresas_id: userId,
      }

      const { id } = await api.post('api/v1/boletos/store', data).then(res => res.data.dados.boleto)
      const boletoPdf = await api.post('api/v1/boletos/viewBoleto', {
        boleto: id,
      }, { responseType: 'arraybuffer' }).then(res => new File([res.data], 'Boleto.pdf', {
        type: 'application/pdf',
      }))

      const demonstrativo = await api.post(`api/v1/acordo/viewDemoSimples/${acordo.id}`, null, {
        responseType: 'arraybuffer',
      }).then(res => new File([res.data], 'Demonstrativo.pdf', {
        type: 'application/pdf',
      }))
      return { boletoPdf, demonstrativo }
    },

    async enviarEmail(boleto, demonstrativo, acordo) {
      const { cliente } = this

      const body = new FormData()
      body.append('name', `${this.fisrtLastName(cliente.nome)}`)
      body.append('to', JSON.stringify(this.emailsCliente.map(email => ({
        name: this.fisrtLastName(cliente.nome), address: email,
      }))))
      body.append('idAcordo', acordo.id)
      body.append('boleto', boleto)
      body.append('demonstrativo', demonstrativo)

      const configEmail = {
        method: 'post',
        url: 'http://192.168.254.219:3000/api/v1/boleto/',
        data: body,
      }

      await api(configEmail)
    },

    fecharAcordo() {
      // eslint-disable-next-line camelcase
      const { executivo_id: executivo_cobranca_id } = localStorage
      // eslint-disable-next-line camelcase
      const adm = !executivo_cobranca_id || executivo_cobranca_id === 'false'
      this.$swal({
        text: 'Deseja gerar o acordo?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sim, gerar!',
        cancelButtonText: 'Cancelar',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(async result => {
        if (result.value) {
          this.storingAcordo = true
          const body = await this.$store.getters['negociar/getBodyAcordo'](adm)
          body.acordo.cliente_id = this.$route.params.id
          const formaPagamento = body.formaPagamento.key
          this.progressMessage = 'GERANDO ACORDO NA BASE'
          const { acordo } = await api.post('api/v1/acordo/store', body).then(res => res.data.dados)
          // const acordo = null
          if (!acordo) {
            this.$swal({
              title: 'Error!',
              text: 'Erro ao gerar Acordo!',
              icon: 'error',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            })
            this.storingAcordo = false
            return null
          }

          this.bar = {
            variant: 'success', value: 25,
          }

          // eslint-disable-next-line camelcase
          if (!adm) {
            switch (formaPagamento) {
              case 'boleto_pix': {
                this.progressMessage = 'GERANDO BOLETO'
                const boleto = await this.gerarBoleto(acordo)
                if (!boleto) {
                  this.$swal({
                    title: 'Error!',
                    text: 'Erro ao gerar Boleto!',
                    icon: 'error',
                    customClass: {
                      confirmButton: 'btn btn-primary',
                    },
                    buttonsStyling: false,
                  })
                  this.bar = {
                    variant: 'success', value: 0,
                  }
                  this.storingAcordo = false
                  return null
                }
                this.bar = {
                  variant: 'success', value: 50,
                }
                this.progressMessage = 'GERANDO PDF'
                const pdf = await this.gerarPdf(boleto, acordo)

                this.bar = {
                  variant: 'success', value: 80,
                }
                this.progressMessage = 'ENVIANDO PARA O CLIENTE'

                await this.enviarEmail(pdf.boletoPdf, pdf.demonstrativo, acordo)
                this.bar = {
                  variant: 'success', value: 90,
                }
                await this.updateTabs()
                this.bar = {
                  variant: 'success', value: 100,
                }
                this.$toast(
                  {
                    component: ToastificationContent,
                    props: {
                      title: 'Sucesso',
                      icon: 'CheckIcon',
                      text: '👋 Acordo gerado e enviado boleto para o email',
                      variant: 'success',
                    },
                  },
                  {
                    position: 'top-center',
                  },
                )
                setTimeout(() => this.toggleNegociar(false), 1000)
                this.storingAcordo = false
                break
              }
              case 'cartao_voz': {
                const demonstrativo = await api.post(`api/v1/acordo/viewDemoSimples/${acordo.id}`, null, {
                  responseType: 'arraybuffer',
                }).then(res => new File([res.data], 'Demonstrativo.pdf', {
                  type: 'application/pdf',
                }))

                const { nome } = this.cliente
                const hash = md5(acordo.numero_acordo).toString()
                const cartaoBody = new FormData()
                cartaoBody.append('name', this.fisrtLastName(nome))
                cartaoBody.append('link', `https://fatura.grupovoz.com.br/cartao/${hash}`)
                cartaoBody.append('demonstrativo', demonstrativo)
                cartaoBody.append('idAcordo', acordo.id)
                cartaoBody.append('to', JSON.stringify(this.emailsCliente.map(email => ({
                  name: this.fisrtLastName(nome), address: email,
                }))))

                const configEmail = {
                  method: 'post',
                  url: 'http://192.168.254.219:3000/api/v1/cartao/voz',
                  data: cartaoBody,
                }
                await api(configEmail)
                await this.updateTabs()
                this.progressMessage = 'ENVIADO!!'
                this.bar = {
                  variant: 'success',
                  value: 100,
                }
                this.$toast(
                  {
                    component: ToastificationContent,
                    props: {
                      title: 'Sucesso',
                      icon: 'CheckIcon',
                      text: '👋 Acordo gerado e enviado fatura para o email',
                      variant: 'success',
                    },
                  },
                  {
                    position: 'top-center',
                  },
                )
                setTimeout(() => this.toggleNegociar(false), 1000)
                this.storingAcordo = false
                break
              }
              case 'cartao_credor': {
                const demonstrativo = await api.post(`api/v1/acordo/viewDemoDetalhado/${acordo.id}`, null, {
                  responseType: 'arraybuffer',
                }).then(res => new File([res.data], 'Demonstrativo.pdf', {
                  type: 'application/pdf',
                }))

                const reprentantes = await api.get(`api/v1/credor_contatos/${this.credorSelected.id}`).then(rep => rep.data.dados)
                const to = reprentantes.map(rep => {
                  // eslint-disable-next-line consistent-return
                  const resp = (rep.relatorios.filter(rel => rel.title.includes('Link'))).length > 0 ? {
                    name: rep.nome,
                    address: rep.email,
                  } : null
                  return resp
                }).filter(Boolean)

                if (to.length > 0) {
                  const cartaoBody = new FormData()
                  cartaoBody.append('to', JSON.stringify(to))
                  cartaoBody.append('demonstrativo', demonstrativo)
                  cartaoBody.append('emails', JSON.stringify(this.emailsCliente.map(email => email)))
                  cartaoBody.append('name', this.cliente.nome)
                  cartaoBody.append('credor', this.credorSelected.nome)
                  cartaoBody.append('cpfCliente', this.cliente.cpf_cnpj)
                  cartaoBody.append('telefones', JSON.stringify([]))
                  cartaoBody.append('idAcordo', acordo.numero_acordo)

                  api.post('http://192.168.254.219:3000/api/v1/cartao/credor', cartaoBody, {}).then(async () => {
                    await this.updateTabs()
                    this.bar = {
                      variant: 'success',
                      value: 100,
                    }
                    this.progressMessage = 'ENVIADO!!'
                    this.$toast(
                      {
                        component: ToastificationContent,
                        props: {
                          title: 'Sucesso',
                          icon: 'CheckIcon',
                          text: '👋 Acordo gerado e enviado para o credor',
                          variant: 'success',
                        },
                      },
                      {
                        position: 'top-center',
                      },
                    )
                    setTimeout(() => this.toggleNegociar(false), 1000)
                    this.bar = {
                      variant: '',
                      value: 0,
                    }
                  })
                }
                this.storingAcordo = false
                break
              }
              case 'maquineta': {
                const demonstrativo = await api.post(`api/v1/acordo/viewDemoDetalhado/${acordo.id}`, null, {
                  responseType: 'arraybuffer',
                }).then(res => new File([res.data], 'Demonstrativo.pdf', {
                  type: 'application/pdf',
                }))

                const reprentantes = await api.get(`api/v1/credor_contatos/${this.credorSelected.id}`).then(rep => rep.data.dados)
                // eslint-disable-next-line consistent-return
                const to = reprentantes.map(rep => {
                  if (rep.relatorios) {
                    const resp = (rep.relatorios.filter(rel => rel.title.includes('Maquineta'))).length > 0 ? {
                      name: rep.nome,
                      address: rep.email,
                    } : null
                    return resp
                  }
                }).filter(Boolean)
                if (to.length > 0) {
                  const cartaoBody = new FormData()
                  cartaoBody.append('to', JSON.stringify(to))
                  cartaoBody.append('demonstrativo', demonstrativo)
                  cartaoBody.append('name', this.cliente.nome)
                  cartaoBody.append('credor', this.credorSelected.nome)
                  cartaoBody.append('cpf', this.cliente.cpf_cnpj)
                  cartaoBody.append('idAcordo', acordo.numero_acordo)
                  cartaoBody.append('vencimento', this.americaDate(this.dataEntrada))

                  api.post('http://192.168.254.219:3000/api/v1/maquineta', cartaoBody, {}).then(async () => {
                    await this.updateTabs()
                    this.bar = {
                      variant: 'success',
                      value: 100,
                    }
                    this.progressMessage = 'ENVIADO!!'
                    this.$toast(
                      {
                        component: ToastificationContent,
                        props: {
                          title: 'Sucesso',
                          icon: 'CheckIcon',
                          text: '👋 Acordo gerado e enviado para o credor',
                          variant: 'success',
                        },
                      },
                      {
                        position: 'top-center',
                      },
                    )
                    setTimeout(() => this.toggleNegociar(false), 1000)
                    this.bar = {
                      variant: '',
                      value: 0,
                    }
                  })
                } else {
                  this.$toast(
                    {
                      component: ToastificationContent,
                      props: {
                        title: 'Aviso!!',
                        icon: 'CheckIcon',
                        text: '⚓ Credor não possui representante',
                        variant: 'warning',
                      },
                    },
                    {
                      position: 'top-center',
                    },
                  )
                }
                this.storingAcordo = false
                break
              }
              default:
                this.storingAcordo = false
                break
            }
            this.storingAcordo = false
          } else {
            this.storingAcordo = false
            await this.updateTabs()
            this.$toast(
              {
                component: ToastificationContent,
                props: {
                  title: 'Sucesso',
                  icon: 'CheckIcon',
                  text: '👋 Negociação Administrativa concluida',
                  variant: 'success',
                },
              },
              {
                position: 'top-center',
              },
            )
            setTimeout(() => this.toggleNegociar(false), 1000)
            this.bar = {
              variant: 'success',
              value: 100,
            }
          }
        }
        this.storingAcordo = false
        return null
      })
    },
    createProposta() {
      this.$swal({
        text: 'Deseja gerar Proposta?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sim, gerar!',
        cancelButtonText: 'Cancelar',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(async result => {
        if (result.value) {
          this.bar = {
            variant: 'success', value: 25,
          }
          this.progressMessage = 'GERANDO PROPOSTA'

          const bodyAcordo = await this.$store.getters['negociar/getBodyAcordo']()
          // const { acordo, operacoes, parcelas } = bodyAcordo
          bodyAcordo.acordo.status = 'Analise'
          bodyAcordo.acordo.cliente_id = this.$route.params.id
          bodyAcordo.acordo.totalNominal = this.totalNominal
          bodyAcordo.parametroDefault = this.parametroDefault

          // eslint-disable-next-line camelcase
          const { executivo_id: executivo_cobranca_id } = localStorage

          bodyAcordo.credor = this.credorSelected
          bodyAcordo.cliente = this.cliente

          const body = {
            valor_nominal: this.valorTotal,
            cliente_id: this.$route.params.id,
            // eslint-disable-next-line camelcase
            executivo_cobranca_id: !executivo_cobranca_id || executivo_cobranca_id === 'false' ? process.env.VUE_APP_ROOT_ADM : executivo_cobranca_id,
            proposta: bodyAcordo,
          }

          this.bar = {
            variant: 'success', value: 50,
          }

          const { data } = await api.post('api/v1/proposta/store/json', body).then(res => res)

          this.bar = {
            variant: 'success', value: 75,
          }

          await this.updateTabs()

          this.bar = {
            variant: 'success', value: 100,
          }

          setTimeout(() => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Notificação',
                icon: 'BellIcon',
                text: data.mensagem,
                variant: 'success',
              },
            }, {
              position: 'top-center',
            })
            this.bar = {
              variant: 'success', value: 0,
            }
            this.progressMessage = ''
          }, 1000)
        }
      })
    },
  },
}
</script>
