<template>
  <div>
    <b-card>
      <b-row class="mb-1">
        <b-col md="12">
          <b-button
            variant="primary"
            @click="$refs.anexoModal.show()"
          >
            Novo Anexo
          </b-button>
        </b-col>
      </b-row>
      <b-overlay
        :show="load"
        variant="transparent"
      >
        <b-row>
          <b-col md="12">
            <b-table
              responsive
              :fields="fields"
              :items="items"
            >
              <template #cell(actions)="data">
                <feather-icon
                  icon="EyeIcon"
                  class="color-icon cursor-pointer"
                  @click="preview(data.item)"
                />
              </template>

              <template #cell(data)="data">
                {{ formatTimezone(data.item.data) }}
              </template>
            </b-table>
          </b-col>
        </b-row>
      </b-overlay>
    </b-card>

    <!-- <b-form-file
      v-show="false"
      id="fileUpload"
      ref="file"
      v-model="file"
      accept=".jpg, .jpeg, .png, .pdf"
    /> -->

    <b-modal
      id="preview"
      ref="preview"
      hide-footer
      size="xl"
      title=""
    >
      <iframe
        id="myFrame"
        :src="urlPreview"
      />
    </b-modal>

    <b-modal
      ref="anexoModal"
      no-close-on-backdrop
      title="Anexar arquivo"
      ok-title="Anexar"
      cancel-title="Cancelar"
      cancel-variant="danger"
      :ok-disabled="newPend.length === 0"
      @ok="updatePend"
      @show="limparModal"
    >
      Operações:
      <v-select
        v-model="newPend"
        :options="optionsOperations.options"
        multiple
        class="mb-1"
      />
      Selecione o arquivo:
      <b-form-file
        v-model="file"
        placeholder=""
        drop-placeholder="Arquivo"
      />

    </b-modal>

  </div>
</template>

<script>
import {
  BTable,
  BRow,
  BCol,
  BButton,
  VBModal,
  BFormFile,
  BModal,
  BCard,
  BOverlay,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'

import axios2 from 'axios'
import { mapState } from 'vuex'
import axios from '@/../axios-auth'

export default {
  components: {
    BTable,
    vSelect,
    BRow,
    BCol,
    BButton,
    BFormFile,
    BModal,
    BCard,
    BOverlay,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  props: {
    idCliente: {
      type: Number,
      required: true,
    },
    updateTabs: {
      type: Function,
      required: true,
    },

  },
  data() {
    return {
      newPend: [],
      optionsOperations: {
        options: [],
      },
      select: [],
      operationSelected: [],
      listOperationsCharge: [],
      operacoes: [],
      mtPend: [],

      fields: [
        { key: 'id', text: 'ID', class: 'text-center' },
        { key: 'anexo', text: 'Anexo', class: 'text-center' },
        { key: 'data', text: 'Data', class: 'text-center' },
        { key: 'actions', class: 'text-center' },
      ],
      items: [],
      file: null,
      urlPreview: '',
      load: false,
    }
  },

  computed: {
    ...mapState({
      operacoesCliente: state => {
        const { operacoes } = state.negociacao
        return operacoes
      },
    }),
  },

  watch: {
    operacoesCliente() {
      this.getOperacoes()
    },

    // async file() {
    //   if (this.file) {
    //     await this.sendAnexo()
    //     this.file = null
    //   }
    // },
  },
  created() {
    this.getAnexos()
    this.getPendingReason()
    this.optionsStatusOperations()
    this.getOperacoes()
  },
  methods: {
    async getOperacoes() {
      this.operacoes = this.operacoesCliente
    },

    async getAnexos() {
      this.load = true
      this.items = []
      await axios.get(`api/v1/anexos/listar/${this.idCliente}`).then(res => {
        if (res.data) {
          this.load = false
          // eslint-disable-next-line array-callback-return
          res.data.map(item => {
            this.items.unshift({
              id: item.id,
              anexo: item.nome,
              data: item.created_at,
              anexo_nome: item.numero_anexo,
            })
          })
        }
      })
    },
    async chooseFile() {
      document.getElementById('fileUpload').click()
    },

    async sendAnexo() {
      const formData = new FormData()
      formData.append('file', this.file)

      await axios2.post('http://192.168.254.205/upload', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }).then(res => {
        const body = {
          cliente_id: this.idCliente,
          user_id: Number(localStorage.getItem('userId')),
          numero_anexo: res.data,
          nome: this.file.name,
          info_pendencia: {
            numero_operacao: this.newPend.map(num => num.numero),
            vencimento_operacao: this.newPend.map(ven => ven.vencimento),
            id_operacao: this.newPend.map(id => id.id),
          },
        }
        axios.post('api/v1/anexos/store', body, {
          headers: {
            Accept: 'application/json',
            Authorization: 'Bearer '.concat(localStorage.getItem('token')),
          },
        }).then(() => {
          this.getAnexos()
          this.$swal({
            title: 'Sucesso!',
            text: 'Anexo enviado',
            icon: 'success',
            showConfirmButton: false,
            timer: 1500,
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
        })
      })
    },
    async preview(item) {
      this.urlPreview = `http://192.168.254.205/anexos/${item.anexo_nome}`
      this.$refs.preview.show()
    },

    async getPendingReason() {
      await axios.get('api/v1/info/motivopendencia', {
        headers: {
          Authorization: 'Bearer '.concat(localStorage.getItem('token')),
          accept: 'application/json',
        },
      }).then(res => {
        res.data.dados[14].map(dt => {
          this.mtPend.push(dt.id)
        })
      })
    },

    async optionsStatusOperations() {
      this.operacoesCliente.map(item => {
        this.optionsOperations.options.push({
          label: `${item.numero_operacao} - ${item.data_vencimento}`,
          id: item.id,
          numero: item.numero_operacao,
          vencimento: item.data_vencimento,
        })
      })
    },

    async updatePend() {
      const operacoesIds = []
      this.newPend.map(id => {
        operacoesIds.push(id.id)
      })
      const body = {
        ops_ids: operacoesIds,
        motivo_pendencia_id: 1,
        description: '',
        motivoId: 12,
      }

      await axios.put('api/v1/operacoes/update/lote/motivo/pendencia/', body, {
        headers: {
          Accept: 'application/json',
          Authorization: 'Bearer '.concat(localStorage.getItem('token')),
        },
      }).then(() => {
        this.$swal({
          title: 'Pendência alterada!',
          text: 'Alteração executada com sucesso!',
          icon: 'success',
          showConfirmButton: false,
          timer: 1500,
        })
      }).catch(() => {
        this.$swal({
          title: 'ERROR!',
          text: 'Erro ao executar alteração!',
          icon: 'error',
          customClass: {
            comfirmButton: 'btn btn-danger',
          },
        })
      })
      await this.sendAnexo()
      await this.updateTabs()
      await this.sendLog()
    },
    limparModal() {
      this.newPend = []
    },
    // Função para alimentar  a tabela de log de anexos na primeira vez que o anexo é enviado, um anexo padrão
    async sendLog() {
      const arrayAnexo = this.items
      const arrayIdAnexo = []
      arrayAnexo.map(id => {
        arrayIdAnexo.push(id.id)
      })
      const arrayOrdenado = arrayIdAnexo.sort((a, b) => b - a)

      const ultimoIdAnexo = arrayOrdenado[0]

      const body = {
        anexo_id: ultimoIdAnexo,
        status: 'RECEBIDO',
        usuario: localStorage.getItem('userName'),
      }
      await axios.post('api/v1/log/anexos/store', body, {
        headers: {
          Accept: 'application/json',
          Authorization: 'Bearer '.concat(localStorage.getItem('token')),
        },
      })
    },
  },
}
</script>
<style>
    #myFrame {
      width:100%;
      height:800px;
      border: none;
    }
</style>
